import React, { useState } from 'react';
import '../styles/App.sass';
import {
    Footer,
    Header,
    Funciones,
    Notas,
    Intro,
    // Spotify,
    // Map,
    // Patrocinantes,
    Productoras,
    // CountDown,
    // PuntoDeVentas,
    // MetodosDePagos,
    Terminos,
    FullFunctions,
    Galeria
    // Snacks,
} from '../components';
import { Parallax } from 'react-parallax';
// import { Help } from '../components/iconSvg';
// import { Link } from 'react-router-dom';
// import moment from 'moment-timezone';

const Home = () => {
    const MONEDAS = ['USD', 'VES'];
    // const MONEDAS = ['USD'];
    const [moneda, setMoneda] = useState(MONEDAS[0]);
    const [allowBuy] = useState(true);
    // const DIFF_HOR_SEC = moment(
    //     moment().format('DD/MM/YYYY hh:mm a'), 'DD/MM/YYYY hh:mm a'
    // ).diff(
    //     moment(
    //         moment().tz('America/Caracas').format('DD/MM/YYYY hh:mm a'), 'DD/MM/YYYY hh:mm a'
    //     ),
    //     'seconds'
    // );

    const fullFunctions = true
    return (
        <>
            <Header />
            <Parallax blur={0} bgImage="/images/background.jpg" bgImageAlt="field" strength={100}>
                <div className='pt-5'>
                    <div className="container-custom-tm mb-5">
                        {/* <CountDown
                            date={moment('15/10/2023 11:00 am', 'DD/MM/YYYY hh:mm a').add(
                                DIFF_HOR_SEC,
                                'seconds'
                            )}
                            onFinish={() => setAllowBuy(true)}
                        /> */}
                        <div className="row">
                            {!fullFunctions && (
                                <>
                                    <div className="col-12 col-xl-6 d-flex flex-column mb-4">
                                        <Intro intro={
                                            <>
                                                <p><b>Academia 318</b></p>
                                                <p>Academia de formación de futuros titanes</p>
                                                <p>Consiste en reunir durante 5 días a 400 niños y jóvenes entre 6 a 17 años por ciudad. Objetivos: Aprender técnicas nuevas, que se diviertan y queden impregnados del Sentimiento Vinotinto. Incluso se quiere: Descubrir nuevos talentos. Utilizar tecnología en sus uniformes para conocer las estadísticas de su desempeño con la marca de GPS Oliver. Certificar con diploma de asistencia avalado por la FVF.</p>
                                            </>
                                        }/>
                                    </div>
                                    <div className="col-12 col-xl-6 d-flex flex-column mb-4">
                                        <Notas
                                            notas={[
                                                '7 Ciudades, 5 Días por Ciudad, 2.5 horas de campamento intensivo diario',
                                                'El paquete base del campamento incluye: 1 Uniforme (camisa, short y medias), 1 Mochila, Hidratación y refrigerios (fruta, barritas)',
                                                'ADICIONAL (a la venta): Uniformes adicionales, GPS Oliver: OLIVER es un mini GPS con ciencia deportiva y una app integrada que ofrece información vital para mejorar el juego de los atletas, evitar lesiones y medir su rendimiento. Permite comparar métricas con profesionales y jugadores del mismo nivel, recibe recomendaciones para progresar y participa en desafíos y rankings para ganar premios, retar a amigos y otros usuarios de OLIVER, acumular puntos de experiencia y seguir el progreso con reportes semanales, mensuales y anuales compartibles con entrenadores.'
                                            ]}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                        {fullFunctions ? (
                            <div className="mb-4">
                                <FullFunctions
                                    moneda={moneda}
                                    setMoneda={setMoneda}
                                    monedas={MONEDAS}
                                    allowBuy={allowBuy}
                                />
                            </div>
                        ) : (
                            <div className='row mb-4'>
                                <Funciones
                                    moneda={moneda}
                                    setMoneda={setMoneda}
                                    monedas={MONEDAS}
                                    allowBuy={allowBuy}
                                />
                            </div>
                        )}
                        <div className="row mb-4">
                            <div className="col-12 col-md-12 col-lg-12 mb-12">
                                <Galeria/>
                            </div>
                            {/*
                            <div className="col-12 col-md-12 col-lg-4 mb-4">
                                <Spotify inline={true} Spotifyurl="https://open.spotify.com/embed/artist/5v3TYN6Mmi5vxV2uSOfXot?utm_source=generator"/>
                            </div>
                            */}
                        </div>
                        <div className="row">
                            <div className="col-12 col-xxl-4 col-xl-6 d-flex mb-4 align-items-stretch">
                                <Productoras />
                            </div>
                            <div className="col-12 col-xxl-8 col-xl-6 d-flex mb-4 ">
                                <Terminos texto={
                                    <>
                                        1.La inscripción adquirida es responsabilidad del individuo que realiza la compra. En caso de mal uso o divulgación de información de la inscripción a terceros, se exonera de toda responsabilidad a TICKETMUNDO.<br/>
                                        2.El participante se compromete a cumplir con las normas de seguridad establecidas para el campamento.<br/>
                                        3.Se deberá mostrar el recibo de esta inscripción electrónica si así lo requiere el organizador del evento. En tales casos, se notificará al titular de la compra con anticipación a través de correo electrónico.<br/>
                                        4.Seguido de la realización de la compra, la persona responsable recibirá un correo electrónico para completar el formulario de inscripción para los participantes inscritos. Este correo podrá tardar hasta 24h en llegar al correo electrónico.<br/>
                                        5.Una vez pagada la inscripción, no se podrá solicitar reembolso.<br/>
                                        6.Una vez enviado el formulario de inscripción, los participantes no pueden ser cambiados.<br/>
                                        7.Los organizadores se reservan el derecho de admisión y permanencia en el campamento de cualquier persona cuyo comportamiento perturbe el desarrollo normal del evento, así como el ingreso de alimentos, bebidas u otros objetos prohibidos.<br/>
                                        8.En caso de cancelación del campamento, la empresa organizadora procederá a la devolución del monto pagado por las inscripciones, excluyendo las comisiones cobradas por TICKETMUNDO.<br/>
                                        9.En caso de compras con tarjetas internacionales, deberá enviar a través del correo de <a href="mailto:verificacion.compras@ticketmundo.com" rel="noreferrer" style={{ color: `var(--bg__btn)` }}>verificacion.compras@ticketmundo.com</a>, los siguientes recaudos para la validación de la compra: Foto de la cédula de identidad, pasaporte u otro documento de identificación. Imagen de la tarjeta utilizada para la compra, mostrando solo los 4 últimos dígitos. Planilla de validación: <a href="https://cdn.ticketmundo.live/documentos/Ticketmundo-Carta-Aceptacion-Cargo-Tarjeta-De-Credito-Forma-PV001.pdf" target="_blank" rel="noreferrer" style={{ color: `var(--bg__btn)` }}>https://cdn.ticketmundo.live/documentos/Ticketmundo-Carta-Aceptacion-Cargo-Tarjeta-De-Credito-Forma-PV001.pdf</a>. En caso de consultas comunicarse a través del whatsapp AT <a href="https://wa.me/+584124286237" target="_blank" rel="noreferrer" style={{ color: `var(--bg__btn)` }}>+58 (412) 428-62-37</a><br/>
                                        10.Si, por razones de fuerza mayor no imputables al campamento, se suspende el evento luego de haber comenzado, no se realizará ningún reembolso.<br/>
                                        11.Todo participante debe de venir acompañado por un representante mayor de edad.<br/>
                                        12.El equipamiento incluido con la inscripción deberá ser reclamado según instrucciones enviadas al correo por el organizador del evento una vez realizada la compra.<br/>
                                    </>
                                }/>
                            </div>
                        </div>
                    </div>
                </div>
            </Parallax>
            <Footer />
        </>
    );
};

export default Home;
