import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const RedirectTo = ({ route }) => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate(route);
    }, [navigate]);

    return null
}

export default RedirectTo
