import React from 'react';

const IntroWithTitle = ({ title, intro }) => {
    return (
        <>
            <section className="ld--intro ld--card">
                <h2 className="ld--title">{title}</h2>
                {intro}
            </section>
        </>
    );
};

export default IntroWithTitle;
