import React, { useState } from 'react';

import { LogoTM, Share } from './iconSvg';
import ModalShare from './ModalShare';
// import axios from 'axios';
//
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';

const Header = () => {
    const delay = 5000
    const [urlShort] = useState(window.location.href);
    const [showShare, setShowShare] = useState(false);
    const handleShare = async () => {
        // const { status, data } = await axios.post(`Configuracion/AcortarUrl`, {
        //     url: window.location.href
        // });
        setShowShare(true);
    };
    const pagination = {
        clickable: true,
        renderBullet: function (_, className) {
            return '<span class="' + className + ' buttonsGallery"></span>';
        }
    };
    return (
        <header className="ld--header ">
            <div className="fixed-top">
                <div className="container-custom-tm">
                    <a href="https://ticketmundo.com.ve/">
                        <LogoTM />
                    </a>
                </div>
            </div>
            <figure className="urlBanner">
                <Swiper
                    style={{ background: 'black' }}
                    spaceBetween={10}
                    slidesPerView={1}
                    autoplay={{ delay, disableOnInteraction: false }}
                    pagination={pagination}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                >
                    <SwiperSlide>
                        <img
                            className="imgGal"
                            src={`${process.env.REACT_APP_URL_IMAGES}banner-desktop-1.jpg`}
                            alt="1"
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="imgGal"
                            src={`${process.env.REACT_APP_URL_IMAGES}banner-desktop-2.jpg`}
                            alt="2"
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="imgGal"
                            src={`${process.env.REACT_APP_URL_IMAGES}banner-desktop-3.jpg`}
                            alt="2"
                        />
                    </SwiperSlide>
                </Swiper>
            </figure>
            <figure className="urlBannerMovil">
                <Swiper
                    style={{ background: 'black' }}
                    spaceBetween={10}
                    slidesPerView={1}
                    autoplay={{ delay, disableOnInteraction: false }}
                    pagination={pagination}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                >
                    <SwiperSlide>
                        <img
                            className="imgGal"
                            src={`${process.env.REACT_APP_URL_IMAGES}banner-movil-1.jpg`}
                            alt="1"
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="imgGal"
                            src={`${process.env.REACT_APP_URL_IMAGES}banner-movil-2.jpg`}
                            alt="2"
                        />
                    </SwiperSlide>
                    <SwiperSlide>
                        <img
                            className="imgGal"
                            src={`${process.env.REACT_APP_URL_IMAGES}banner-movil-3.jpg`}
                            alt="2"
                        />
                    </SwiperSlide>
                </Swiper>
            </figure>
            <div className="content-bottom">
                <div className="container-custom-tm">
                    <div className="wrap-button">
                        <button className="share" onClick={handleShare}>
                            <Share />
                        </button>
                    </div>
                </div>
            </div>
            <ModalShare
                linkFunc={urlShort}
                show={showShare}
                handleClose={() => {
                    setShowShare(false);
                }}
            />
        </header>
    );
};

export default Header;
